<template>
  <v-container class="nav-bar-box">
    <v-row>
      <v-col cols="4" sm="4" class="d-flex align-center">
        <router-link to="/">
          <v-img
            contain
            class="nav-bar-box-left-logo"
            :width="logoWidth"
            :height="logoHeight"
            :src="require('../assets/home/logo.png')"
          ></v-img
        ></router-link>
      </v-col>
      <v-col cols="8" sm="8" class="d-flex align-center justify-end">
        <template v-if="deviceType !== 'sm' && deviceType !== 'xs'">
          <div
            class="nav-bar-box-li"
            v-for="(nav, index) in navList"
            :key="index"
            @click="goOtherPage(nav)"
          >
            <div
              class="nav-bar-box-li-a nav-bar-box-li-a-active"
              v-if="scaleXNav === nav.name"
            >
              {{ nav.text }}
            </div>
            <div v-else class="nav-bar-box-li-a">
              {{ nav.text }}
            </div>
          </div>
        </template>
        <locale-language></locale-language>
        <template v-if="deviceType === 'sm' || deviceType === 'xs'">
          <v-menu
            offset-y
            origin="center center"
            class="elelvation-1"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on }">
              <v-btn text slot="activator" v-on="on">
                <v-img
                  contain
                  :src="require('../assets/menu.png')"
                  width="30px"
                  height="40px"
                ></v-img>
              </v-btn>
            </template>
            <!-- 显示所有语言包组成的下拉菜单 -->
            <v-list>
              <v-list-item-group v-model="scaleXNav">
                <v-list-item
                  @click="handleChangeLocale(item)"
                  v-for="item in navList"
                  :key="item.name"
                  :value="item.name"
                  :disabled="item.name === scaleXNav ? true : false"
                >
                  <v-list-item-title v-text="item.text" />
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="4">
        <crumbs></crumbs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// 顶部导航
import Crumbs from "./crumbs.vue";
import LocaleLanguage from "./LocaleLanguage.vue";
import ComputedMix from "@/mixins/computed.js";
export default {
  mixins: [ComputedMix],
  components: { Crumbs, LocaleLanguage },
  data() {
    return {};
  },
  computed: {
    logoWidth() {
      if (this.deviceType === "xs" || this.deviceType === "sm") {
        return "65px";
      } else {
        return "150px";
      }
    },
    logoHeight() {
      if (this.deviceType === "xs" || this.deviceType === "sm") {
        return "10px";
      } else {
        return "21px";
      }
    },
    scaleXNav() {
      return this.$route.name;
    },
    navList() {
      return [
        {
          href: "/",
          name: "home",
          text: this.$vuetify.lang.t("$vuetify.navbar.home"),
        },
        {
          href: "/about",
          name: "about",
          text: this.$vuetify.lang.t("$vuetify.navbar.about"),
        },
        {
          href: "/contact",
          name: "contact",
          text: this.$vuetify.lang.t("$vuetify.navbar.contact"),
        },
        {
          href: "/ItSystem",
          name: "ItSystem",
          text: this.$vuetify.lang.t("$vuetify.navbar.ItSystem"),
        },
      ];
    },
  },
  methods: {
    goOtherPage(item) {
      if (this.$route.name !== item.name) {
        this.$router.push({ path: item.href });
      }
    },
    handleChangeLocale(item) {
      this.$router.push({ path: item.href });
    },
  },
};
</script>

<style scoped>
/* .nav-bar-box {
	width: 100vw;
} */

.nav-bar-box-left-logo {
  margin-left: 30px;
  cursor: pointer;
}
.nav-bar-box-li {
  margin-right: 40px;
  display: inline-block;
}
.nav-bar-box-li-a {
  font-size: 18px;
  display: block;
  font-weight: 600;
  color: #2c3235;
  text-decoration: none;
  padding: 40px 0;
  position: relative;
  cursor: pointer;
}
.nav-bar-box-li-a-active:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 24px;
  height: 2px;
  width: 100%;
  /* transform: scaleX(0); */
  background-color: #2c3235;
  transform-origin: 0 100%;
  transition: all 0.25s ease;
}
</style>
