var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"about-page"},[_c('div',{staticClass:"about-large-img-box"},[_c('v-img',{attrs:{"min-height":"400","max-height":"600","src":require('../assets/about/1.jpg')}}),_vm._m(0)],1),_c('h2',{staticClass:"about-page-one-title"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.about.oneTitle'))+" ")]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"about-page-p-center"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.about.p1'))+" ")]),_c('p',{staticClass:"about-page-p-center"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.about.p2'))+" ")])])],1)],1),_c('v-carousel',{staticStyle:{"margin":"50px 0"},attrs:{"cycle":true,"interval":100000,"hide-delimiters":""},model:{value:(_vm.activeOne),callback:function ($$v) {_vm.activeOne=$$v},expression:"activeOne"}},_vm._l((_vm.oneSwipers),function(item,index){return _c('v-carousel-item',{key:index,attrs:{"src":item.src}},[(!_vm.show)?_c('div',{staticClass:"about-page-carousel-box"},[_c('div',{class:[
						index === 2 || index === 3 ? 'left-box' : 'right-box',
						'about-page-carousel-box-content' ]},[_c('h1',[_vm._v(_vm._s(item.text))]),_c('p',[_vm._v(_vm._s(item.position))]),_c('h3',[_vm._v(_vm._s(item.companyName))])])]):_vm._e()])}),1),(_vm.show)?_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"about-page-carousel-box-show",attrs:{"cols":"12"}},[_c('h1',[_vm._v(_vm._s(_vm.oneSwipers[_vm.activeOne].text))]),_c('p',[_vm._v(_vm._s(_vm.oneSwipers[_vm.activeOne].position))]),_c('h3',[_vm._v(_vm._s(_vm.oneSwipers[_vm.activeOne].companyName))])])],1)],1):_vm._e(),_c('v-container',{style:({
			padding: _vm.paddingValue,
		}),attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"sm":"12","xs":"12","md":"6","lg":"6","xl":"4"}},[_c('h3',{staticClass:"about-page-span-two"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.about.text1'))+" ")]),_c('p',{staticClass:"about-page-p-one"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.about.text2'))+" ")]),_c('p',[_c('span',{staticClass:"about-page-span-one"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.about.text3')))]),_c('span',{staticClass:"about-page-span-three"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.about.text4')))]),_c('span',{staticClass:"about-page-span-one"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.about.text5')))])])]),_c('v-col',{attrs:{"sm":"12","xs":"12","md":"6","lg":"4","xl":"4"}},[_c('v-responsive',{attrs:{"aspect-ratio":1 / 1}},[_c('v-img',{staticStyle:{"border-radius":"10px"},attrs:{"src":require('../assets/about/3.jpg')}})],1)],1)],1)],1),_c('v-carousel',{staticStyle:{"margin-bottom":"50px"},attrs:{"cycle":true,"interval":100000,"hide-delimiters":""},model:{value:(_vm.activeTwo),callback:function ($$v) {_vm.activeTwo=$$v},expression:"activeTwo"}},_vm._l((_vm.twoSwipers),function(item,index){return _c('v-carousel-item',{key:index,attrs:{"src":item.src}},[(!_vm.show)?_c('div',{staticClass:"about-page-carousel-box"},[_c('div',{staticClass:"left-box about-page-carousel-box-content"},[_c('h1',[_vm._v(_vm._s(item.text))]),_c('p',[_vm._v(_vm._s(item.position))]),_c('h3',[_vm._v(_vm._s(item.companyName))])])]):_vm._e()])}),1),(_vm.show)?_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"about-page-carousel-box-show",attrs:{"cols":"12"}},[_c('h1',[_vm._v(_vm._s(_vm.twoSwipers[_vm.activeTwo].text))]),_c('p',[_vm._v(_vm._s(_vm.twoSwipers[_vm.activeTwo].position))]),_c('h3',[_vm._v(_vm._s(_vm.twoSwipers[_vm.activeTwo].companyName))])])],1)],1):_vm._e(),_c('v-container',{style:({
			padding: _vm.paddingValue,
		}),attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"sm":"12","xs":"12","md":"6","lg":"6","xl":"4"}},[_c('v-responsive',{attrs:{"aspect-ratio":1 / 1}},[_c('v-img',{staticStyle:{"border-radius":"10px"},attrs:{"src":require('../assets/about/7.jpg')}})],1)],1),_c('v-col',{attrs:{"sm":"12","xs":"12","md":"6","lg":"4","xl":"4"}},[_c('h3',{staticClass:"about-page-span-two"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.about.introduceTitle'))+" ")]),_c('p',{staticClass:"about-page-p-one"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.about.introduceP1'))+" ")]),_c('p',{staticClass:"about-page-p-one"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.about.introduceP2'))+" ")]),_c('p',{staticClass:"about-page-p-one"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.about.introduceP3'))+" ")])])],1)],1),_c('v-container',{style:({
			padding: _vm.paddingValue,
			marginTop: '60px',
		}),attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"sm":"12","xs":"12","md":"6","lg":"6","xl":"4"}},[_c('h3',{staticClass:"about-page-span-two"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.about.measureTitle'))+" ")]),_c('p',{staticClass:"about-page-p-one"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.about.measureSubTitle'))+" ")]),_c('ul',{staticClass:"about-page-ul"},[_c('li',{staticClass:"about-page-ul-li"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.about.measureP1'))+" ")]),_c('li',{staticClass:"about-page-ul-li"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.about.measureP2'))+" ")]),_c('li',{staticClass:"about-page-ul-li"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.about.measureP3'))+" ")]),_c('li',{staticClass:"about-page-ul-li"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.about.measureP4'))+" ")])])]),_c('v-col',{attrs:{"sm":"12","xs":"12","md":"6","lg":"4","xl":"4"}},[_c('v-responsive',{attrs:{"aspect-ratio":10 / 9}},[_c('v-img',{staticStyle:{"border-radius":"10px"},attrs:{"src":require('../assets/about/8.jpg')}})],1)],1)],1)],1),_c('v-carousel',{staticStyle:{"margin-bottom":"50px"},attrs:{"cycle":true,"interval":100000,"hide-delimiters":""},model:{value:(_vm.activeThree),callback:function ($$v) {_vm.activeThree=$$v},expression:"activeThree"}},_vm._l((_vm.threeSwipers),function(item,index){return _c('v-carousel-item',{key:index,attrs:{"src":item.src}},[(!_vm.show)?_c('div',{staticClass:"about-page-carousel-box"},[_c('div',{class:[
						index === 0 || index === 3 ? 'right-box' : 'left-box',
						'about-page-carousel-box-content' ]},[_c('h1',[_vm._v(_vm._s(item.text))]),_c('p',[_vm._v(_vm._s(item.position))]),_c('h3',[_vm._v(_vm._s(item.companyName))])])]):_vm._e()])}),1),(_vm.show)?_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"about-page-carousel-box-show-other",attrs:{"cols":"12"}},[_c('h1',[_vm._v(_vm._s(_vm.threeSwipers[_vm.activeThree].text))]),_c('p',[_vm._v(_vm._s(_vm.threeSwipers[_vm.activeThree].position))]),_c('h3',[_vm._v(_vm._s(_vm.threeSwipers[_vm.activeThree].companyName))])])],1)],1):_vm._e(),_c('h2',{staticClass:"about-page-one-title"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.about.twoTitle'))+" ")]),_c('v-container',{style:({
			padding: _vm.paddingValue,
		}),attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"sm":"12","xs":"12","md":"6","lg":"6","xl":"4"}},[_c('p',{staticClass:"about-page-p-one"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.about.p3'))+" ")]),_c('p',{staticClass:"about-page-p-one"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.about.p4'))+" ")])]),_c('v-col',{attrs:{"sm":"12","xs":"12","md":"6","lg":"4","xl":"4"}},[_c('p',{staticClass:"about-page-p-one"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.about.p5'))+" ")]),_c('p',{staticClass:"about-page-p-one"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.about.p6'))+" ")])])],1)],1),(_vm.fourSwipers.length > 0)?_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"sm":"12","xs":"12","md":"12","lg":"8","xl":"8"}},[_c('v-carousel',{attrs:{"cycle":true,"interval":100000,"show-arrows":false,"hide-delimiters":""},model:{value:(_vm.activeFour),callback:function ($$v) {_vm.activeFour=$$v},expression:"activeFour"}},_vm._l((_vm.fourSwipers),function(item,index){return _c('v-carousel-item',{key:index},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"sm":"12","xs":"6","md":"7","lg":"7","xl":"4"}},[_c('v-img',{staticClass:"about-page-four-swiper-img",attrs:{"min-height":"400px","max-height":"600px","src":item.src}})],1),(_vm.deviceType !== 'xs' && _vm.deviceType !== 'sm')?_c('v-col',{staticClass:"d-flex flex-column justify-center",attrs:{"sm":"12","xs":"6","md":"5","lg":"5","xl":"4"}},[_c('h1',{staticClass:"about-page-four-swiper-h1"},[_vm._v(_vm._s(item.year))]),_c('p',{staticClass:"about-page-four-swiper-p"},[_vm._v(_vm._s(item.comment))])]):_vm._e()],1)],1)],1)}),1)],1)],1)],1):_vm._e(),(
			(_vm.deviceType === 'xs' || _vm.deviceType === 'sm') && _vm.fourSwipers.length > 0
		)?_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"about-page-carousel-box-show-other",attrs:{"cols":"12"}},[_c('h1',[_vm._v(_vm._s(_vm.fourSwipers[_vm.activeFour].year))]),_c('p',[_vm._v(_vm._s(_vm.fourSwipers[_vm.activeFour].comment))])])],1)],1):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"about-large-img-title-box"},[_c('h1',[_vm._v("One Team. One Goal.")])])}]

export { render, staticRenderFns }