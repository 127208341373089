<!-- src/components/LocaleLanguage.vue -->
<template>
  <div>
    <!-- 多语言选择菜单 -->
    <v-menu
      offset-y
      origin="center center"
      class="elelvation-1"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn text slot="activator" v-on="on">
          <v-icon medium>mdi-translate</v-icon>
          <!-- 显示当前语言名称 -->
          <span class="ml-2"> {{ localeText }} </span>
          <v-icon small>mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <!-- 显示所有语言包组成的下拉菜单 -->
      <v-list>
        <v-list-item-group v-model="$vuetify.lang.current">
          <v-list-item
            @click="handleChangeLocale(item)"
            v-for="item in availableLanguages"
            :key="item.value"
            :value="item.value"
            :disabled="item.value === lang ? true : false"
          >
            <v-list-item-title v-text="item.text" />
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "locale-language",
  methods: {
    // 设置当前语言
    handleChangeLocale({ value }) {
      const { current } = this.$vuetify.lang;
      if (current !== value) {
        this.$vuetify.lang.current = value;
        this.$store.dispatch("set_lang", value);
      }
      // 修改语言后刷新title
      location.reload();
    },
  },
  watch: {
    lang(value) {
      this.$vuetify.lang.current = value;
      this.$store.dispatch("set_lang", value);
    },
  },
  computed: {
    // 遍历并获取项目中的所有语言包
    availableLanguages() {
      const { locales } = this.$vuetify.lang; // 所有的语言，来自 src/plugins/vuetify.js 中的配置
      let list = [];
      for (let key in locales) {
        let p = {
          text: locales[key].navbar.label, // 语言包显示的名字
          value: key, // 语言包的值
        };
        list.push(p);
      }

      return list;
    },
    // 当前语言, 用于语言切换菜单中显示的当前语言名字
    localeText() {
      const find = this.availableLanguages.find(
        (item) => item.value === this.$vuetify.lang.current
      );
      return find.text;
    },
    lang() {
      return this.$store.state.lang;
    },
  },
};
</script>

<style></style>
