<template>
	<div class="home-page">
		<div class="home-large-img-box">
			<v-img
				min-height="600"
				max-height="850"
				:src="require('../assets/home/1.jpg')"
			></v-img>
			<div class="home-large-img-title-box">
				<div class="home-large-img-title">
					<h1>{{ $vuetify.lang.t('$vuetify.home.h1') }}</h1>
					<p style="margin-top: 30px">
						{{ $vuetify.lang.t('$vuetify.home.p1') }}
					</p>
				</div>
			</div>
			<span class="home-large-img-icon"></span>
		</div>
		<div
			class="home-swiper-img-box"
			:style="{
				padding: paddingOne,
			}"
		>
			<v-img
				height="300px"
				:src="require('../assets/home/2.jpg')"
				style="border-radius: 10px"
			></v-img>
		</div>
		<div
			class="home-page-content"
			:style="{
				padding: paddingTwo,
			}"
		>
			<h1 class="home-page-content-h1">
				{{ $vuetify.lang.t('$vuetify.home.h2') }}
			</h1>
			<p class="home-page-content-p">
				{{ $vuetify.lang.t('$vuetify.home.p2') }}
			</p>
			<p class="home-page-content-p">
				{{ $vuetify.lang.t('$vuetify.home.p3') }}
			</p>
			<p class="home-page-content-p">
				{{ $vuetify.lang.t('$vuetify.home.p4') }}
			</p>
		</div>

		<v-container
			fluid
			class="home-page-container-box"
			:style="{
				padding: paddingTwo,
			}"
		>
			<h1 class="home-page-content-h1">
				{{ $vuetify.lang.t('$vuetify.home.h3') }}
			</h1>
			<v-row>
				<v-col sm="12" xs="12" md="6" lg="6" xl="6">
					<h2 class="home-page-content-h2">INSIGHTS by Schattdecor</h2>
					<v-responsive :aspect-ratio="3 / 2">
						<v-img
							style="border-radius: 10px"
							:src="require('../assets/home/12.jpg')"
						></v-img>
					</v-responsive>
				</v-col>
				<v-col sm="12" xs="12" md="6" lg="6" xl="6">
					<v-responsive :aspect-ratio="1 / 1">
						<v-img
							style="border-radius: 10px"
							:src="require('../assets/home/13.jpg')"
						></v-img>
					</v-responsive>
				</v-col>
				<v-col sm="12" xs="12" md="6" lg="6" xl="6">
					<p
						:class="[
							deviceType === 'xs' || deviceType === 'sm'
								? ''
								: 'home-page-content-p-text',
						]"
					>
						{{ $vuetify.lang.t('$vuetify.home.p5') }}
					</p>
				</v-col>
				<v-col sm="12" xs="12" md="6" lg="6" xl="6">
					<v-responsive
						:aspect-ratio="1 / 1"
						:class="[
							deviceType === 'xs' || deviceType === 'sm'
								? ''
								: 'home-page-content-last-img',
						]"
					>
						<v-img
							style="border-radius: 10px"
							:src="require('../assets/home/14.jpg')"
						></v-img>
					</v-responsive>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
// @ is an alias to /src
import ComputedMix from '@/mixins/computed.js'
export default {
	name: 'Home',
	mixins: [ComputedMix],
	computed: {
		paddingOne() {
			if (this.deviceType === 'xs' || this.deviceType === 'sm') {
				return '30px 20px'
			} else {
				return '50px 45px'
			}
		},
		paddingTwo() {
			if (this.deviceType === 'xs' || this.deviceType === 'sm') {
				return '0 20px'
			} else {
				return '0 45px'
			}
		},
	},
}
</script>
<style scoped>
.home-page {
	width: 100%;
}
.home-large-img-box {
	width: 100%;
	position: relative;
}
.home-large-img-title-box {
	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.home-large-img-title {
	padding: 0 40px;
	box-sizing: border-box;

	color: #fff;
	width: 100%;
}
.home-large-img-icon {
	width: 20px;
	min-width: 20px;
	height: 40px;
	border-radius: 80px;
	border: 2px solid #fff;
	display: flex;
	flex-direction: column;
	position: absolute;
	bottom: 45px;
	z-index: 2;
	left: 50%;
	transform: translate(-50%);
}
.home-large-img-icon::before {
	content: '';
	display: block;
	position: absolute;
	bottom: -20px;
	left: 50%;
	width: 8px;
	height: 13px;
	background-image: url("data:image/svg + xml;charset=utf-8,%3Csvgheight='13'width='8'xmlns='http://www.w3.org/2000/svg'%3E%3Cpathd='M5.323 6.49L.125 11.523c-.167.155-.167.309 0 .463l.933.897c.166.155.332.155.498 0l6.35-6.163C7.97 6.663 8 6.586 8 6.489s-.031-.173-.093-.231L1.557.095c-.146-.136-.312-.126-.499.028L.125.992c-.167.154-.167.308 0 .463z'fill='%23fff'/%3E%3C/svg%3E");
	transform: translate(-50%) rotate(90deg);
}
.home-large-img-icon::after {
	content: '';
	display: block;
	position: absolute;
	top: 8px;
	left: 50%;
	transform: translate(-50%);
	width: 3px;
	height: 6px;
	border-radius: 10px;
	background-color: #fff;
	animation-name: scroll-animate;
	animation-duration: 1.4s;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
	animation-fill-mode: forwards;
	animation-timing-function: ease-in-out;
}

.home-swiper-img-box {
	width: 100%;
	/* padding: 50px 45px; */
	box-sizing: border-box;
}

.home-page-content {
	width: 100%;
	/* padding: 0 45px; */
	box-sizing: border-box;
}
.home-page-content-h1 {
	margin-bottom: 30px;
	text-align: center;
}
.home-page-content-p {
	color: #70808c;
	text-align: center;
}

.home-page-container-box {
	width: 100%;
	/* padding: 0 40px; */
	box-sizing: border-box;
}

.home-page-content-h2 {
	color: #2c3235;
	line-height: 1.5;
	margin-bottom: 20px;
}
.home-page-content-p-text {
	padding-right: 100px;
	box-sizing: border-box;
}
.home-page-content-last-img {
	position: relative;
	top: -60px;
	left: -60px;
}
</style>
