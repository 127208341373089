<template>
	<v-breadcrumbs
		divider=">"
		:items="items"
		v-if="items.length > 0"
	></v-breadcrumbs>
</template>

<script>
// 面包屑
export default {
	computed: {
		items() {
			let list = []
			if (this.$route.name !== 'home') {
				const q = {
					disabled: false,
					href: '/',
					text: this.$vuetify.lang.t(`$vuetify.navbar.home`),
				}
				const p = {
					disabled: true,
					href: this.$route.path,
					text: this.$vuetify.lang.t(`$vuetify.navbar.${this.$route.name}`),
				}
				list.push(q)
				list.push(p)
			}
			return list
		},
	},
}
</script>

<style></style>
