<template>
  <div>
    <div class="main-content">
      <div class="flex-col">
        <div class="col xs-12">
          <div v-if="id == 1" class="image-teaser-container">
            <div class="element-headline-container">
              <h2>{{ $vuetify.lang.t("$vuetify.contact.china") }}</h2>
            </div>
            <div class="image-container" data-image="0">
              <div class="image">
                <img :src="image3" />
              </div>
            </div>
            <div class="element-content-container">
              <p>
                {{ $vuetify.lang.t("$vuetify.contact.shanghai") }}
                <br />
                {{ $vuetify.lang.t("$vuetify.contact.pdp") }}
                <br />
                <br />
                {{ $vuetify.lang.t("$vuetify.contact.cdmc") }}
                <br />
                {{ $vuetify.lang.t("$vuetify.contact.kerp") }}
                <br />
                201315
                <br />
              </p>
              <!-- <div class="button type-2 color-3">
                  {{ this.$vuetify.lang.t("$vuetify.contact.contact") }}
                </div> -->
              <a
                class="button type-2 color-3"
                href="mailto:info@schattdecor.cn"
                style="text-decoration: none"
                >{{ $vuetify.lang.t("$vuetify.contact.contact") }}</a
              >
            </div>
            <div class="image-container" data-image="1">
              <div class="image">
                <figure>
                  <img :src="image4" />
                </figure>
              </div>
            </div>
            <div class="image-container" data-image="2">
              <div class="image">
                <figure>
                  <img :src="image5" />
                </figure>
              </div>
            </div>
          </div>
          <div class="text-media-block-element" v-if="id == 2">
            <div class="flex-col row-inner">
              <v-row>
                <v-col cols="12" lg="6" md="6" xl="6" sm="12" xs="12">
                  <div class="col xs-12 m-6 media-block-column">
                    <div class="image">
                      <figure>
                        <picture>
                          <img :src="image6" />
                        </picture>
                      </figure>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" lg="6" md="6" xl="6" sm="12" xs="12">
                  <div class="col xs-12 m-6 media-block-column">
                    <div class="content-container-element">
                      <h2>
                        {{ $vuetify.lang.t("$vuetify.contact.china") }}
                      </h2>
                      <p class="align-right">
                        <strong style="font-weight: 700">{{
                          $vuetify.lang.t("$vuetify.contact.quzhou")
                        }}</strong>
                        <br />
                        {{ $vuetify.lang.t("$vuetify.contact.pdp") }}
                        <br />
                        <br />
                        {{ $vuetify.lang.t("$vuetify.contact.zsnmc") }}
                        <br />
                        {{ $vuetify.lang.t("$vuetify.contact.lnd8") }}
                        <br />
                        324000
                      </p>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
          <div style="width: 100%; height: 500px"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      image3: require("@/assets/contact/3.jpg"),
      image4: require("@/assets/contact/4.jpg"),
      image5: require("@/assets/contact/5.jpg"),
      image6: require("@/assets/contact/6.jpg"),
      id: "",
    };
  },
  created() {
    this.id = this.$route.params.id;
  },
};
</script>

<style>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

.text-media-block-element .image,
.text-media-block-element .image img {
  overflow: hidden;
  border-radius: 10px;
}
@media only screen and (max-width: 767px) {
  .text-media-block-element .media-block-column {
    order: 1;
  }
}
@media only screen and (max-width: 767px) {
  .flex-col .col,
  .flex-col.content {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media only screen and (min-width: 1px) {
  .xs-12 {
    width: 100%;
  }
}
.content-container-element b,
.content-container-element em,
.content-container-element i,
.content-container-element li,
.content-container-element p,
.content-container-element span,
.content-container-element strong,
.content-container-element u {
  font-size: 1.2rem;
  line-height: 2rem;
  color: #70808c;
}

.content-container-element h2,
.content-container-element h3,
.content-container-element h4,
.content-container-element h5,
.content-container-element h6 {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .flex-col .col,
  .flex-col.content {
    padding-right: 25px;
    padding-left: 25px;
  }
}
@media only screen and (min-width: 768px) {
  .m-6 {
    width: 50%;
  }
}
@media only screen and (min-width: 1px) {
  .xs-12 {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .flex-col.row-inner {
    margin-left: -25px;
    margin-right: -25px;
  }
}
.main-content {
  max-width: 1220px;
  margin: 0 auto;
  width: 100%;
  position: relative;
}
.flex-col {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .flex-col .col,
  .flex-col.content {
    padding-right: 25px;
    padding-left: 25px;
  }
}
@media only screen and (min-width: 1px) {
  .xs-12 {
    width: 100%;
  }
}
p {
  /* padding: 0 0 25px; */
  font-weight: 300;
}
body {
  color: #2c3235;
}
.button.type-2.color-3 {
  background: #2c3235;
  border: 2px solid #2c3235;
  color: #fff;
  transition: all 0.25s ease;
}
.button.type-2.color-3:hover {
  background: #fff;
  border: 2px solid #2c3235;
  color: #2c3235;
  transition: all 0.25s ease;
}
.button.type-2 {
  background: #fff;
  border: 0;
  color: #2c3235;
}
.button {
  padding: 14px 18px;
  font-size: 1.1rem;
  line-height: 1.1rem;
  font-weight: 700;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background: #2c3235;
  border: 0;
  color: #fff;
}
.button:hover {
  color: #2c3235;
  background: #fff;
}
@media (min-width: 768px) {
  .image-teaser-container:after {
    content: "";
    display: block;
    width: 61%;
    height: 41%;

    left: 48px;
    position: absolute;
    z-index: 1;
    border-left: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    top: 20%;
  }
}
@media (min-width: 768px) {
  .image-teaser-container .image-container .image img {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .image-teaser-container .image-container {
    width: 100%;
    order: 2;
    margin-top: 25px;
  }
}
@media (min-width: 768px) {
  .image-teaser-container .image-container[data-image="2"] {
    width: 45%;
    height: 45%;
    position: absolute;
    left: 40%;
    top: 39%;
    z-index: 3;
  }
}
@media (min-width: 1024px) {
  .image-teaser-container .element-content-container {
    padding: 25px 0 0 75px;
  }
}
@media (min-width: 768px) {
  .image-teaser-container .element-content-container {
    padding: 25px 0 0 65px;
    width: 33.333%;
    position: relative;
    z-index: 4;
  }
}

@media (min-width: 768px) {
  .image-teaser-container .image-container[data-image="1"] {
    width: 48%;
    height: 100%;
    position: absolute;
    top: 20px;
    right: 0;
    z-index: 2;
  }
}
.element-content-container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
img {
  max-width: 100%;
  display: block;
}
.image img {
  border-radius: 10px;
  overflow: hidden;
}
.image {
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
}
@media (min-width: 768px) {
  .image-teaser-container .image-container[data-image="0"] {
    width: 48%;
    position: relative;
    z-index: 2;
  }
}
.element-headline-container {
  margin-bottom: 30px;
}
h2 {
  font-size: 1.9rem;
  color: #2c3235;
}
.mage-teaser-container {
  width: 100%;
  position: relative;
}
</style>
