export default {
	data() {
		return {
			deviceType: '', // xs <600px ,600px< sm <960px ,960< md <1264px , 1264px< lg < 1904px , xl > 1904px
		}
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.getDeviceType, { passive: true })
	},
	watch: {
		deviceType(value) {
			this.deviceType = value
		},
	},
	created() {
		this.getDeviceType()
		window.addEventListener('resize', this.getDeviceType, { passive: true })
	},
	methods: {
		getDeviceType() {
			this.deviceType = this.$vuetify.breakpoint.name || 'md'
			console.log('设备类型：', this.deviceType)
		},
	},
}
