<template>
  <v-app v-title :data-title="getTitle">
    <navbar></navbar>
    <v-main>
      <router-view />
    </v-main>
    <page-footer></page-footer>
  </v-app>
</template>
<script>
import pageFooter from './components/page-footer.vue';
import Navbar from './components/navbar.vue';
export default {
  components: { Navbar, pageFooter },
  computed: {
    getTitle() {
      // if (this.$vuetify.lang.current == 'en') {
      //   return 'Schattdecor | Passionate Surface Specialist';
      // } else {
      return '夏特装饰材料（上海）有限公司';
      // }
    }
  }
};
</script>

<style>
#app {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  overflow-y: auto;
}
</style>
