import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "core-js/stable";
import "regenerator-runtime/runtime";
import vuetify from "@/plugins/vuetify"; // path to vuetify export

Vue.config.productionTip = false;

Vue.directive("title", {
  inserted: function (el) {
    document.title = el.dataset.title;
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
