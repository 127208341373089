export const en = {
  label: "English",
  home: "Home",
  about: "About us",
  contact: "Contact us",
  contactDetail: "Contact us",
  ItSystem: "IT System",
};

export const zhHans = {
  label: "简体中文",
  home: "首页",
  about: "关于我们",
  contact: "联系我们",
  contactDetail: "联系我们",
  ItSystem: "信息系统",
};
