import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		lang: Cookies.get('language') || 'zhHans',
	},
	mutations: {
		SET_LANG(state, lang) {
			state.lang = lang
			Cookies.set('language', lang)
		},
	},
	actions: {
		set_lang({ commit }, lang) {
			commit('SET_LANG', lang)
		},
	},
	modules: {},
})
