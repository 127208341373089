<template>
  <v-footer dark padless>
    <v-card class="flex" flat tile>
      <v-card-text class="py-2 white--text text-center">
        <div style="width: 100%; margin: 0 auto; padding: 20px 0">
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502018800"
            class="link-a"
            ><img
              :src="require('../assets/footer/recordIcon.png')"
              style="float: left"
            />
            <p style="margin: 0px 0px 0px 5px">
              沪公网安备 31011502018800号
            </p></a
          >
          <a href="https://beian.miit.gov.cn" class="link-a">
            <p style="margin: 0px 0px 0px 25px">
              schattdecor © 沪ICP备18024547号-1
            </p>
          </a>
        </div>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {};
</script>

<style scoped>
.link-a {
  display: inline-block;
  text-decoration: none;
  height: 20px;
  line-height: 20px;
}
p {
  float: left;
  height: 20px;
  line-height: 20px;
  color: #939393;
}
</style>
