export const en = {
	h1: 'One Source. Unlimited Solutions.',
	p1: 'Our decors make every area of life more beautiful.',
	h2: 'Welcome to Schattdecor, the surface specialist.',
	p2: 'Our international family-owned company is the global market leader in decor printing and has been supplying customers, from many industries, with high-quality decors and products, sophisticated advice and reliable service since its foundation in 1985.',
	p3: 'Schattdecor surfaces can be found all over the world: they are used, for example, to cover furniture and laminate flooring and also play an important role in caravan/RV interiors and public spaces.',
	p4: 'We owe our continued success to the enthusiasm and creative power of our team. Their ideas and motivation move our company forward every day. The fact that Schattdecor has been able to set new milestones, in both design and technology, for many decades, is the result of their outstanding work.',
	h3: 'Extra Exciting: Our Design & Trend Magazine',
	p5: 'Our online magazine focuses on the questions of how people want to live and work, which trend worlds will accompany us in the long term and where our industry is heading in the future. Read the answers from our international design, marketing and communications team in exciting articles, interviews and blog posts.',
}

export const zhHans = {
	h1: '无限美好，源于专注！',
	p1: '我们的饰面让生活中目之所及出变得更加美丽',
	h2: '欢迎来到夏特，我们是饰面专家',
	p2: '作为一家国际性家族企业，我们是饰面印刷全球市场的领导者。自1985年成立以来，我们一直为来自多个行业的客户提供高品质的饰面和产品、完善的咨询建议和可靠的服务。',
	p3: '夏特饰面遍布世界各地：例如，它们被用于覆盖家具和强化地板，并且在旅行房车的内饰以及公共场所中也发挥着重要作用。',
	p4: '我们持续取得的成功归功于员工的热情和创造力。他们的创意和积极性每天都在推动着公司向前发展。几十年来夏特能够在设计和技术上不断树立新的里程碑，都是他们杰出工作的结果。',
	h3: '动人心弦：我们的设计与流行杂志',
	p5: '我们的在线杂志关注的问题包括：人们想要如何生活和工作？从长远的角度将有怎样的世界流行趋势伴随我们？以及我们行业未来的发展方向是什么？这些问题的答案就在我们国际设计、市场和企业交流团队所呈现的精彩的文章、访谈和博客中。',
}
