import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Contact from "../views/Contact.vue";
import ContactDetail from "../views/ContactDetail.vue";
import ItSystem from "../views/ItSystem.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
  {
    path: "/contactDetail/:id",
    name: "contactDetail",
    component: ContactDetail,
  },
  {
    path: "/ItSystem",
    name: "ItSystem",
    component: ItSystem,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
