export const en = {
  oneTitle: " Who we are – How we work",
  p1: "We're 2,800 employees from 27 nations at 16 locations around the world.",
  p2: "With all of our colleagues and skills combined, we’re a really strong team united with a common goal: We're determined to inspire our customers in the woodworking products industry with our high-quality, on trend decors and surfaces that are true to our motto: “One Source. Unlimited Solutions”.",
  oneSwiper: [
    {
      text: "“Schattdecor is my second family. The company is always behind me.”",
      position: "Marcus Edwards | Printer",
      companyName: "Schattdecor USA",
    },
    {
      text: "“Schattdecor has taught me that the big picture is made up of more than its individual parts.”",
      position: "Basil Corera | Managing Director",
      companyName: "Schattdecor Malaysia",
    },
    {
      text: "“At Schattdecor, you can feel the family atmosphere. It gives us security, creates real team spirit and is simply fun!”",
      position: "Alexander Boldyrew | Product Manager Flooring, CRM",
      companyName: "Schattdecor Russia",
    },
    {
      text: "“We're passionate about our goals and proud of our shared successes.”",
      position: "Sinem Degerli | Design Team Leader",
      companyName: "Schattdecor Turkey",
    },
    {
      text: "“Cultures from all over the world now form a large community at Schattdecor. We respect and enrich each other.”",
      position: "Christoph Kuchler | SAP Logistics/B2B",
      companyName: "Schattdecor Germany",
    },
  ],
  text1: "Qualität „Made by Schattdecor“",
  text2:
    "‘Made by Schattdecor’ – with this globally applicable quality seal, we guarantee consistently high quality standards across all of our locations. We define standardized product characteristics, work processes and compatible production machinery all from the same system. In addition, we stand out from the crowd thanks to customer-oriented service, and intensively maintained and personal contact with customers and partners.",
  text3: "Our goal is to provide sophisticated solutions in ",
  text4: "decor development, application technology and trend consulting. ",
  text5:
    ".As a result, we've established “Made by Schattdecor” as the standard for proven quality in the industry for over three and a half decades.",
  twoSwiper: [
    {
      text: "“In our working environment we have the freedom to express our ideas and opinions – approaching executives or management is easy and straightforward.”",
      position: "Monica Prates | Supervisor",
      companyName: "Schattdecor Brazil",
    },
    {
      text: "“We treat each other like family, trust each other, and have confidence in what we're doing.”",
      position: "Guilherme Moreno | Logistics",
      companyName: "Schattdecor Brazil",
    },
    {
      text: "“I feel the confidence placed in me every day. It's really motivating.”",
      position: "Davide Vacchini | Shift Supervisor Printing",
      companyName: "Schattdecor Italy",
    },
  ],
  introduceTitle: "Sustainability at Schattdecor",
  introduceP1:
    "Consistent protection of natural resources and a respectful attitude towards people, nature and the environment have been among our central corporate goals since Schattdecor was founded in 1985. Their implementation is an important investment in our future. We use only environmentally friendly printing inks and resins which are practically solvent-free thus protecting the health of both our employees and the environment.",
  introduceP2:
    "We ensure minimal emissions by using state of the art production facilities, energy-optimized processes, and by continuously investing in environmentally friendly, future-oriented technologies. By implementing these forward looking processes it allows us to act quickly and in a market-oriented manner. Water used during production and our solvent-free paints are recycled after manufacturing so that they can safely be reintroduced into the environment.",
  introduceP3:
    "Our production standards across the world follow our high, German environmental standards. Our locations are certified by the Forest Stewardship Council® (FSC® license code FSC-C104949, FSC-C111992, FSC-C121647).",
  measureTitle: " Energy management at Schattdecor",
  measureSubTitle:
    " We have defined principles for dealing with energy in our daily activities:",
  measureP1:
    "We are improving our energy management and continuously reducing our energy consumption. We regularly inform our employees about our energy consumption and motivate them to adopt energy-saving behavior. We set ourselves regular goals to increase energy efficiency. Resources are made available to achieve the goals.",
  measureP2:
    "We pay attention to energy efficiency in the design, procurement and operation of our systems and machines.",
  measureP3: "The economical use of energy is our priority.",
  measureP4: "We want to implement the energy measures throughout the group.",

  threeSwiper: [
    {
      text: "“Our understanding of appreciation starts with the greeting – from trainees to the Executive Board. Often a simple 'Hi' is enough.”",
      position: "Johanna Millauer | Sales Office Europe South",
      companyName: "Schattdecor Germany",
    },
    {
      text: "“We're in a constant process of further development – supported by colleagues and partners from all over the world. We benefit from the cooperation with other cultures.”",
      position: "Wictor Boyarintsev | Head of Production/Technology/AWT",
      companyName: "Schattdecor Russia",
    },
    {
      text: "“Respectful and honest relationships are at the heart of our great culture!”",
      position: "Lindsay Myers | Sales",
      companyName: "Schattdecor USA",
    },
    {
      text: "“Seeing employees as fellow human beings, understanding the workplace as a living space, allowing closeness, remaining respectful, creating trust, giving confidence and gaining loyalty – that's the way to win people over.”",
      position: "Walter Schatt",
      companyName: "Company founder",
    },
    {
      text: "“Through the interaction of development, experience and technology, we've succeeded in designing surfaces that serve market-specific trends and requirements.”",
      position: "Ralf Peschel | Head of the Impregnation Division",
      companyName: "Schattdecor Germany",
    },
  ],
  twoTitle: "Our Success Story",
  p3: "The 1980s. Germany: Walter Schatt reaches a decision. He wants to revolutionize the decor printing industry with a new approach to quality as well as a holistic approach to solutions and environmental awareness.",
  p4: "In the Rosenheim region, he inspires a strong network with his vision and receives valuable support from the very beginning. Reliable, personal contacts and other favorable conditions give him reason to leave his workplace in the Swabian Alps behind and found a company in Rosenheim. ",
  p5: "A handful of colleagues from his former business environment move with him, and their families follow.",
  p6: "On April 26, 1985, Schattdecor prints its first roll of decor paper in a small industrial hall in Stephanskirchen. Today, 2,800 Schattdecor employees from 27 nations produce 2.4 billion square meters per year. How did it all begin? From a vision to market leader. From Europe to Asia and America. From a decor printer to a surface specialist. Join us as we take you on a journey through 35 years of company history.",
};

export const zhHans = {
  oneTitle: "我们是谁 – 我们如何工作",
  p1: "我们拥有来自27个国家的2800名员工，在全球建立了16个生产基地。",
  p2: "公司同事具备专业知识和技能，我们的团队实力雄厚，并且有着一致的目标：为木材加工行业客户提供灵感、优质产品和时尚花色，真正践行公司理念：“无限美好，源于专注。”",
  oneSwiper: [
    {
      text: "“夏特就是我的第二个家，总是给予我支持。”",
      position: "Marcus Edwards | 印刷机操作员",
      companyName: "夏特美国公司",
    },
    {
      text: "“夏特让我明白一个道理，整体总是由不同个体组成。”",
      position: "Basil Corera | 市场和销售经理",
      companyName: "夏特马来西亚",
    },
    {
      text: "“夏特公司让我感受到了家的温暖，给了我安全感，塑造了团队精神，在这里工作的每一天都很快乐。”",
      position: "Alexander Boldyrew | 客户管理部 地板产品经理",
      companyName: "夏特德国公司",
    },
    {
      text: "“我们坚信使命必达，以公司的成功为荣。”",
      position: "Sinem Degerli | 设计主管",
      companyName: "夏特土耳其公司",
    },
    {
      text: "“夏特公司就像诺亚方舟，汇聚世界各地文化，我们彼此尊重，彼此成就。”",
      position: "Christoph Kuchler | SAP物流/B2B",
      companyName: "夏特德国公司",
    },
  ],
  text1: "夏特制造，质量保证",
  text2:
    "夏特制造是统一各生产基地的产品质量标准，确定标准化产品特征，规范加工工序，采用同一系统生产设备。经过不懈努力，终于将“夏特制造”打造成通行全球的闪亮名片。夏特公司脱颖而出的另一制胜法宝是，永远将客户摆在第一位，随时与客户及合作伙伴保持密切联系和良好的伙伴关系。",
  text3: "我们的目标是，",
  text4: "在装饰纸研发上精益求精，不断开发新技术，提供潮流趋势咨询服务",
  text5: "。夏特35年来的励精图治终有回报，“夏特制造”成为业内公认的质量标准。",
  twoSwiper: [
    {
      text: "“工作环境给与了我们充分的言论自由，领导层没有架子，很有亲和力，大家沟通很顺畅。”",
      position: "Monica Prates | 主管",
      companyName: "夏特巴西公司",
    },
    {
      text: "“同事彼此之间亲如家人，大家彼此信任，彼此支持”",
      position: "Guilherme Moreno | 物流",
      companyName: "夏特巴西公司",
    },
    {
      text: "“我能感受到公司对我的信任，这种感觉润物无声，对我是莫大的激励。”",
      position: "Davide Vacchini | 印刷主管",
      companyName: "夏特意大利公司",
    },
  ],
  introduceTitle: "夏特公司的可持续发展观",
  introduceP1:
    "夏特公司自1985年成立以来，一直践行保护自然资源，尊重自然，尊重环境，以人为本的公司理念。这样的理念未来还将继续传承下去。为保护员工健康和自然环境，夏特公司只使用可溶性的环保型油墨和天然树脂。",
  introduceP2:
    "采用先进生产设施，提高工序中的能源使用效率，不断投资面向未来的环保科技，实现节能减排最大化。通过采取上述前瞻性措施，夏特能够快速应对外界变化，灵活满足市场需求。工业废水和可溶性油墨在使用后将回收处理，达到环保要求后再排放。",
  introduceP3:
    "各地夏特分公司均执行严格的环保标准，所有生产基地均取得森林管理委员会认证®（FSC®认证编码FSC-C104949, FSC-C111992, FSC-C121647）。",
  measureTitle: " 我们的节能措施",
  measureSubTitle: " 夏特公司在日常工作中形成了一整套明确的节能理念:",
  measureP1:
    "改进能源管理，持续节能减排；定期组织培训，普及能耗常识，鼓励节能实践；节能增效常态化、目标化，调动资源支持目标达成。",
  measureP2: "提高能源利用率贯穿于设备设计、采购和操作全过程。",
  measureP3: "节能最大化是公司不懈的追求。",
  measureP4: "在全集团内践行节能措施。",

  threeSwiper: [
    {
      text: "“公司的实习生也好，领导们也好，对人都非常友好，我想这就是一种欣赏，话不必多说，一句问候足以。”",
      position: "Johanna Millauer | 夏特南欧销售员",
      companyName: "夏特德国公司",
    },
    {
      text: "“世界各地的同事们大力支持，业界同仁鼎力相助，公司业务蒸蒸日上，跨文化合作就是我们的成功之源。”",
      position: "Wiktor Bojarintsew | 生产/技术主管",
      companyName: "夏特俄罗斯公司",
    },
    {
      text: "“公司文化的核心是尊重与诚信”",
      position: "Lindsay Myers | 销售",
      companyName: "夏特美国公司",
    },
    {
      text: "“将员工看作家人，将工作场所看成自己的家，建立亲密关系，互相尊重，彼此信任，相互支持，忠诚守信-这就是夏特能够广纳天下英才的秘诀”。",
      position: "Walter Schatt",
      companyName: "公司创始人",
    },
    {
      text: "“公司不断研发，积累经验，开发技术，成功设计出满足市场需求的饰面产品”。",
      position: "Ralf Peschel | 浸渍部主管",
      companyName: "夏特德国公司",
    },
  ],
  twoTitle: "光辉历史",
  p3: "早在上个世纪80年代，Walter Schatt便立志于采用新的产品质量标准以及更周到的产品解决方案，强化环境意识，从而在印刷装饰纸行业掀起变革。",
  p4: "在德国的罗森海姆地区，Walter Schatt凭借其先进理念，激发了强大的人脉网络，获得了各方面的有力支持。在天时地利人和具备的情况下，他下定决心，从原来位于Swabian Alps地区的公司离职，随后于罗森海姆地区创立了夏特公司。",
  p5: "当时有多名同事携家属随他一同创业。",
  p6: "1985年4月26日，夏特公司的首卷装饰纸诞生于Stephanskirchen的一间小厂房内。现如今，夏特公司已拥有来自27个国家的2800名员工，年均产能高达24亿平方米。夏特公司是如何让梦想照进现实的呢？最初，夏特只是播种下梦想的种子，如今已成苍天大树，行业翘楚；最初的夏特未曾踏出欧洲市场，如今已叩开亚洲和美洲的大门。夏特公司秉承匠心，最终成为饰面专家。让我们带您共同领略过去35年来夏特公司的光华璀璨。",
};
