import zhHansLocale from 'vuetify/es5/locale/zh-Hans' // 导入Vuetify 自带的简体中文语言包
import enLocale from 'vuetify/es5/locale/en'
import store from '@/store/index'

const languages = ['zhHans', 'en']
const value = (function () {
	const obj = {}
	languages.forEach((lang) => {
		obj[lang] = {}
	})
	return obj
})()

// https://webpack.js.org/guides/dependency-management/#requirecontext
const pageFiles = require.context('./page-lang', true, /\.js$/)

// it will auto require lang files from ./page-lang
const pages = pageFiles.keys().reduce((pages, filePath) => {
	// set './xxx.js' => 'xxx'
	const pageName = filePath.replace(/^\.\/(.*)\.\w+$/, '$1')
	const value = pageFiles(filePath)
	languages.forEach((lang) => {
		pages[lang][pageName] = value[lang]
	})
	return pages
}, value)

export const en = {
	...pages.en,
	...enLocale,
}

export const zhHans = {
	...pages.zhHans,
	...zhHansLocale,
}

export const currentLang = store.state.lang
