<template>
	<div class="about-page">
		<div class="about-large-img-box">
			<v-img
				min-height="400"
				max-height="600"
				:src="require('../assets/about/1.jpg')"
			></v-img>
			<div class="about-large-img-title-box">
				<h1>One Team. One Goal.</h1>
			</div>
		</div>

		<h2 class="about-page-one-title">
			{{ $vuetify.lang.t('$vuetify.about.oneTitle') }}
		</h2>
		<v-container>
			<v-row>
				<v-col cols="12">
					<p class="about-page-p-center">
						{{ $vuetify.lang.t('$vuetify.about.p1') }}
					</p>
					<p class="about-page-p-center">
						{{ $vuetify.lang.t('$vuetify.about.p2') }}
					</p>
				</v-col>
			</v-row>
		</v-container>

		<!-- 第一个轮播 -->
		<v-carousel
			v-model="activeOne"
			:cycle="true"
			:interval="100000"
			style="margin: 50px 0"
			hide-delimiters
		>
			<v-carousel-item
				v-for="(item, index) in oneSwipers"
				:key="index"
				:src="item.src"
			>
				<div class="about-page-carousel-box" v-if="!show">
					<div
						:class="[
							index === 2 || index === 3 ? 'left-box' : 'right-box',
							'about-page-carousel-box-content',
						]"
					>
						<h1>{{ item.text }}</h1>
						<p>{{ item.position }}</p>
						<h3>{{ item.companyName }}</h3>
					</div>
				</div>
			</v-carousel-item>
		</v-carousel>

		<v-container v-if="show">
			<v-row>
				<v-col class="about-page-carousel-box-show" cols="12">
					<h1>{{ oneSwipers[activeOne].text }}</h1>
					<p>{{ oneSwipers[activeOne].position }}</p>
					<h3>{{ oneSwipers[activeOne].companyName }}</h3>
				</v-col>
			</v-row>
		</v-container>

		<v-container
			fluid
			:style="{
				padding: paddingValue,
			}"
		>
			<v-row class="d-flex justify-center">
				<v-col sm="12" xs="12" md="6" lg="6" xl="4">
					<h3 class="about-page-span-two">
						{{ $vuetify.lang.t('$vuetify.about.text1') }}
					</h3>
					<p class="about-page-p-one">
						{{ $vuetify.lang.t('$vuetify.about.text2') }}
					</p>
					<p>
						<span class="about-page-span-one">{{
							$vuetify.lang.t('$vuetify.about.text3')
						}}</span>
						<span class="about-page-span-three">{{
							$vuetify.lang.t('$vuetify.about.text4')
						}}</span>
						<span class="about-page-span-one">{{
							$vuetify.lang.t('$vuetify.about.text5')
						}}</span>
					</p>
				</v-col>
				<v-col sm="12" xs="12" md="6" lg="4" xl="4">
					<v-responsive :aspect-ratio="1 / 1">
						<v-img
							style="border-radius: 10px"
							:src="require('../assets/about/3.jpg')"
						></v-img>
					</v-responsive>
				</v-col>
			</v-row>
		</v-container>

		<!-- 第二个轮播 -->

		<v-carousel
			v-model="activeTwo"
			:cycle="true"
			:interval="100000"
			hide-delimiters
			style="margin-bottom: 50px"
		>
			<v-carousel-item
				v-for="(item, index) in twoSwipers"
				:key="index"
				:src="item.src"
			>
				<div class="about-page-carousel-box" v-if="!show">
					<div class="left-box about-page-carousel-box-content">
						<h1>{{ item.text }}</h1>
						<p>{{ item.position }}</p>
						<h3>{{ item.companyName }}</h3>
					</div>
				</div>
			</v-carousel-item>
		</v-carousel>

		<v-container v-if="show">
			<v-row>
				<v-col class="about-page-carousel-box-show" cols="12">
					<h1>{{ twoSwipers[activeTwo].text }}</h1>
					<p>{{ twoSwipers[activeTwo].position }}</p>
					<h3>{{ twoSwipers[activeTwo].companyName }}</h3>
				</v-col>
			</v-row>
		</v-container>

		<v-container
			fluid
			:style="{
				padding: paddingValue,
			}"
		>
			<v-row class="d-flex justify-center">
				<v-col sm="12" xs="12" md="6" lg="6" xl="4">
					<v-responsive :aspect-ratio="1 / 1">
						<v-img
							style="border-radius: 10px"
							:src="require('../assets/about/7.jpg')"
						></v-img>
					</v-responsive>
				</v-col>
				<v-col sm="12" xs="12" md="6" lg="4" xl="4">
					<h3 class="about-page-span-two">
						{{ $vuetify.lang.t('$vuetify.about.introduceTitle') }}
					</h3>
					<p class="about-page-p-one">
						{{ $vuetify.lang.t('$vuetify.about.introduceP1') }}
					</p>
					<p class="about-page-p-one">
						{{ $vuetify.lang.t('$vuetify.about.introduceP2') }}
					</p>
					<p class="about-page-p-one">
						{{ $vuetify.lang.t('$vuetify.about.introduceP3') }}
					</p>
				</v-col>
			</v-row>
		</v-container>
		<v-container
			fluid
			:style="{
				padding: paddingValue,
				marginTop: '60px',
			}"
		>
			<v-row class="d-flex justify-center">
				<v-col sm="12" xs="12" md="6" lg="6" xl="4">
					<h3 class="about-page-span-two">
						{{ $vuetify.lang.t('$vuetify.about.measureTitle') }}
					</h3>
					<p class="about-page-p-one">
						{{ $vuetify.lang.t('$vuetify.about.measureSubTitle') }}
					</p>
					<ul class="about-page-ul">
						<li class="about-page-ul-li">
							{{ $vuetify.lang.t('$vuetify.about.measureP1') }}
						</li>
						<li class="about-page-ul-li">
							{{ $vuetify.lang.t('$vuetify.about.measureP2') }}
						</li>
						<li class="about-page-ul-li">
							{{ $vuetify.lang.t('$vuetify.about.measureP3') }}
						</li>
						<li class="about-page-ul-li">
							{{ $vuetify.lang.t('$vuetify.about.measureP4') }}
						</li>
					</ul>
				</v-col>
				<v-col sm="12" xs="12" md="6" lg="4" xl="4">
					<v-responsive :aspect-ratio="10 / 9">
						<v-img
							style="border-radius: 10px"
							:src="require('../assets/about/8.jpg')"
						></v-img>
					</v-responsive>
				</v-col>
			</v-row>
		</v-container>

		<!-- 第三个轮播 -->
		<v-carousel
			v-model="activeThree"
			:cycle="true"
			:interval="100000"
			hide-delimiters
			style="margin-bottom: 50px"
		>
			<v-carousel-item
				v-for="(item, index) in threeSwipers"
				:key="index"
				:src="item.src"
			>
				<div class="about-page-carousel-box" v-if="!show">
					<div
						:class="[
							index === 0 || index === 3 ? 'right-box' : 'left-box',
							'about-page-carousel-box-content',
						]"
					>
						<h1>{{ item.text }}</h1>
						<p>{{ item.position }}</p>
						<h3>{{ item.companyName }}</h3>
					</div>
				</div>
			</v-carousel-item>
		</v-carousel>

		<v-container v-if="show">
			<v-row>
				<v-col class="about-page-carousel-box-show-other" cols="12">
					<h1>{{ threeSwipers[activeThree].text }}</h1>
					<p>{{ threeSwipers[activeThree].position }}</p>
					<h3>{{ threeSwipers[activeThree].companyName }}</h3>
				</v-col>
			</v-row>
		</v-container>

		<h2 class="about-page-one-title">
			{{ $vuetify.lang.t('$vuetify.about.twoTitle') }}
		</h2>
		<v-container
			fluid
			:style="{
				padding: paddingValue,
			}"
		>
			<v-row class="d-flex justify-center">
				<v-col sm="12" xs="12" md="6" lg="6" xl="4">
					<p class="about-page-p-one">
						{{ $vuetify.lang.t('$vuetify.about.p3') }}
					</p>
					<p class="about-page-p-one">
						{{ $vuetify.lang.t('$vuetify.about.p4') }}
					</p>
				</v-col>
				<v-col sm="12" xs="12" md="6" lg="4" xl="4">
					<p class="about-page-p-one">
						{{ $vuetify.lang.t('$vuetify.about.p5') }}
					</p>
					<p class="about-page-p-one">
						{{ $vuetify.lang.t('$vuetify.about.p6') }}
					</p>
				</v-col>
			</v-row>
		</v-container>

		<!-- 第四个轮播 -->
		<v-container v-if="fourSwipers.length > 0">
			<v-row>
				<v-col sm="12" xs="12" md="12" lg="8" xl="8">
					<v-carousel
						v-model="activeFour"
						:cycle="true"
						:interval="100000"
						:show-arrows="false"
						hide-delimiters
					>
						<v-carousel-item v-for="(item, index) in fourSwipers" :key="index">
							<v-container>
								<v-row>
									<v-col sm="12" xs="6" md="7" lg="7" xl="4">
										<v-img
											class="about-page-four-swiper-img"
											min-height="400px"
											max-height="600px"
											:src="item.src"
										></v-img>
									</v-col>
									<v-col
										sm="12"
										xs="6"
										md="5"
										lg="5"
										xl="4"
										class="d-flex flex-column justify-center"
										v-if="deviceType !== 'xs' && deviceType !== 'sm'"
									>
										<h1 class="about-page-four-swiper-h1">{{ item.year }}</h1>
										<p class="about-page-four-swiper-p">{{ item.comment }}</p>
									</v-col>
								</v-row>
							</v-container>
						</v-carousel-item>
					</v-carousel>
				</v-col>
			</v-row>
		</v-container>

		<v-container
			v-if="
				(deviceType === 'xs' || deviceType === 'sm') && fourSwipers.length > 0
			"
		>
			<v-row>
				<v-col class="about-page-carousel-box-show-other" cols="12">
					<h1>{{ fourSwipers[activeFour].year }}</h1>
					<p>{{ fourSwipers[activeFour].comment }}</p>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
// @ is an alias to /src
import ComputedMix from '@/mixins/computed.js'
export default {
	name: 'Home',
	mixins: [ComputedMix],
	data() {
		return {
			activeOne: 0,

			activeTwo: 0,

			activeThree: 0,
			activeFour: 0,
		}
	},
	computed: {
		paddingValue() {
			if (this.deviceType === 'xs' || this.deviceType === 'sm') {
				return '0 20px'
			} else {
				return '0 60px'
			}
		},
		show() {
			if (this.deviceType === 'xs') {
				return true
			} else {
				return false
			}
		},
		oneSwipers() {
			return [
				{
					text: this.$vuetify.lang.t('$vuetify.about.oneSwiper[0].text'),
					position: this.$vuetify.lang.t(
						'$vuetify.about.oneSwiper[0].position'
					),
					companyName: this.$vuetify.lang.t(
						'$vuetify.about.oneSwiper[0].companyName'
					),
					src: require('../assets/about/2.jpg'),
				},
				{
					text: this.$vuetify.lang.t('$vuetify.about.oneSwiper[1].text'),
					position: this.$vuetify.lang.t(
						'$vuetify.about.oneSwiper[1].position'
					),
					companyName: this.$vuetify.lang.t(
						'$vuetify.about.oneSwiper[1].companyName'
					),
					src: require('../assets/about/14.jpg'),
				},
				{
					text: this.$vuetify.lang.t('$vuetify.about.oneSwiper[2].text'),
					position: this.$vuetify.lang.t(
						'$vuetify.about.oneSwiper[2].position'
					),
					companyName: this.$vuetify.lang.t(
						'$vuetify.about.oneSwiper[2].companyName'
					),
					src: require('../assets/about/15.jpg'),
				},
				{
					text: this.$vuetify.lang.t('$vuetify.about.oneSwiper[3].text'),
					position: this.$vuetify.lang.t(
						'$vuetify.about.oneSwiper[3].position'
					),
					companyName: this.$vuetify.lang.t(
						'$vuetify.about.oneSwiper[3].companyName'
					),
					src: require('../assets/about/16.jpg'),
				},
				{
					text: this.$vuetify.lang.t('$vuetify.about.oneSwiper[4].text'),
					position: this.$vuetify.lang.t(
						'$vuetify.about.oneSwiper[4].position'
					),
					companyName: this.$vuetify.lang.t(
						'$vuetify.about.oneSwiper[4].companyName'
					),
					src: require('../assets/about/17.jpg'),
				},
			]
		},
		twoSwipers() {
			return [
				{
					text: this.$vuetify.lang.t('$vuetify.about.twoSwiper[0].text'),
					position: this.$vuetify.lang.t(
						'$vuetify.about.twoSwiper[0].position'
					),
					companyName: this.$vuetify.lang.t(
						'$vuetify.about.twoSwiper[0].companyName'
					),
					src: require('../assets/about/4.jpg'),
				},
				{
					text: this.$vuetify.lang.t('$vuetify.about.twoSwiper[1].text'),
					position: this.$vuetify.lang.t(
						'$vuetify.about.twoSwiper[1].position'
					),
					companyName: this.$vuetify.lang.t(
						'$vuetify.about.twoSwiper[1].companyName'
					),
					src: require('../assets/about/5.jpg'),
				},
				{
					text: this.$vuetify.lang.t('$vuetify.about.twoSwiper[2].text'),
					position: this.$vuetify.lang.t(
						'$vuetify.about.twoSwiper[2].position'
					),
					companyName: this.$vuetify.lang.t(
						'$vuetify.about.twoSwiper[2].companyName'
					),
					src: require('../assets/about/6.jpg'),
				},
			]
		},
		threeSwipers() {
			return [
				{
					text: this.$vuetify.lang.t('$vuetify.about.threeSwiper[0].text'),
					position: this.$vuetify.lang.t(
						'$vuetify.about.threeSwiper[0].position'
					),
					companyName: this.$vuetify.lang.t(
						'$vuetify.about.threeSwiper[0].companyName'
					),
					src: require('../assets/about/9.jpg'),
				},
				{
					text: this.$vuetify.lang.t('$vuetify.about.threeSwiper[1].text'),
					position: this.$vuetify.lang.t(
						'$vuetify.about.threeSwiper[1].position'
					),
					companyName: this.$vuetify.lang.t(
						'$vuetify.about.threeSwiper[1].companyName'
					),
					src: require('../assets/about/10.jpg'),
				},
				{
					text: this.$vuetify.lang.t('$vuetify.about.threeSwiper[2].text'),
					position: this.$vuetify.lang.t(
						'$vuetify.about.threeSwiper[2].position'
					),
					companyName: this.$vuetify.lang.t(
						'$vuetify.about.threeSwiper[2].companyName'
					),
					src: require('../assets/about/11.jpg'),
				},
				{
					text: this.$vuetify.lang.t('$vuetify.about.threeSwiper[3].text'),
					position: this.$vuetify.lang.t(
						'$vuetify.about.threeSwiper[3].position'
					),
					companyName: this.$vuetify.lang.t(
						'$vuetify.about.threeSwiper[3].companyName'
					),
					src: require('../assets/about/12.jpg'),
				},
				{
					text: this.$vuetify.lang.t('$vuetify.about.threeSwiper[4].text'),
					position: this.$vuetify.lang.t(
						'$vuetify.about.threeSwiper[4].position'
					),
					companyName: this.$vuetify.lang.t(
						'$vuetify.about.threeSwiper[4].companyName'
					),
					src: require('../assets/about/13.jpg'),
				},
			]
		},
		fourSwipers() {
			return []
		},
	},
}
</script>
<style scoped>
.about-page {
	width: 100%;
}
.about-large-img-box {
	width: 100%;
	position: relative;
}
.about-large-img-title-box {
	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #fff;
	text-shadow: 0 2px 8px #2c3235;
}

.about-page-one-title {
	margin: 50px 0;
	text-align: center;
	color: #2c3235;
}

.about-page-p-center {
	text-align: center;
	color: #70808c;
}
.about-page-carousel-box {
	position: relative;
	width: 100%;
	height: 100%;
}
.about-page-carousel-box-content {
	position: absolute;
	z-index: 1;
	width: 450px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	text-align: right;
	color: #fff;
	top: 0;
	bottom: 0;
	text-shadow: 0 2px 8px #2c3235;
}
.left-box {
	left: 90px;
}
.right-box {
	right: 90px;
}

.about-page-carousel-box-show {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	padding: 0 20px;
	box-sizing: border-box;
	color: #2c3235;
	text-align: right;
	margin-bottom: 40px;
}
.about-page-carousel-box-show-other {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding: 0 20px;
	box-sizing: border-box;
	color: #2c3235;
	text-align: left;
	margin-bottom: 40px;
}
.about-page-carousel-content {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 100px;
	z-index: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

/* 文本 */
.about-page-span-one {
	color: #70808c;
}
.about-page-span-two {
	color: #70808c;
	margin-bottom: 25px;
}
.about-page-span-three {
	color: #00859b;
	font-weight: 600;
}
.about-page-p-one {
	margin-bottom: 40px;
	color: #70808c;
}

.about-page-ul {
	list-style: none;
	margin-bottom: 25px;
	padding-left: 0;
}
.about-page-ul-li {
	list-style: none;
	position: relative;
	padding-bottom: 5px;
	padding-left: 20px;
	color: #70808c;
}
.about-page-ul-li::before {
	content: '';
	height: 4px;
	width: 4px;
	min-width: 4px;
	display: block;
	margin: 10px 10px 0 0;
	border-radius: 65px;
	position: absolute;
	left: 0;
	background-color: #576066;
}
</style>
