export const en = {
  bpmtitle: "BPM",
  samplesys: "Sample Sys",
  bpminfo:
    "Machine maintenance, repair, routine check and tool manangment system.",
  mpinfo: "Sample request, rendering preview and order trace & track system.",
};

export const zhHans = {
  bpmtitle: "BPM系统",
  samplesys: "样品系统",
  bpminfo: "设备维护、设备保养、综合巡检及工具管理平台。",
  mpinfo: "样品查询，渲染图查阅，订单流程追踪等综合管理平台。",
};
