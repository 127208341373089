<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" lg="5" xl="4" sm="6" xs="12" @click="navToDetail(1)">
          <div class="imageBox">
            <img class="image" :src="img1" />
            <!-- 图片悬浮信息 -->
            <div class="text-content-block">
              <div class="text-block">
                <h3>{{ $vuetify.lang.t("$vuetify.contact.shanghai") }}</h3>
              </div>
              <div class="link-block">
                <v-row>
                  <v-col cols="10">{{
                    $vuetify.lang.t("$vuetify.contact.mici")
                  }}</v-col>
                  <v-col cols="2"></v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" lg="5" xl="4" sm="6" xs="12" @click="navToDetail(2)">
          <div class="imageBox">
            <img class="image" :src="img2" />
            <!-- 图片悬浮信息 -->
            <div class="text-content-block">
              <div class="text-block">
                <h3>{{ $vuetify.lang.t("$vuetify.contact.quzhou") }}</h3>
              </div>
              <div class="link-block">
                <v-row>
                  <v-col cols="10">{{
                    $vuetify.lang.t("$vuetify.contact.mici")
                  }}</v-col>
                  <v-col cols="2"></v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img1: require("@/assets/contact/1.jpg"),
      img2: require("@/assets/contact/2.jpg"),
      mouseenterinto: false,
    };
  },
  methods: {
    navToDetail(index) {
      this.$router.push({
        path: "/ContactDetail/" + index,
      });
    },
  },
};
</script>

<style>
.text-block h3 {
  font-size: 1.4rem;
  line-height: 2.6rem;
  color: #fff;
  font-weight: 600;
  margin-bottom: 10px;
  text-shadow: 0 2px 8px #2c3235;
}
.text-block {
  margin-bottom: 20px;
  padding: 0 0 0 25px;
  width: 75%;
}
.link-block {
  width: 100%;
  background-color: rgba(44, 50, 53, 0.6);
  padding: 15px 25px;
  color: #fff;
  font-weight: 700;
}
.link-block:hover {
  color: #bababa;
  transition: all 0.25s ease;
}
.text-content-block {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
}
.imageBox {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
}
.imageBox:hover > .image {
  transition: all 0.25s ease;
  transform: scale(1.1);
}
.image {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
  transition: all 0.25s ease;
}
</style>
