// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import { en, zhHans, currentLang } from '@/locale/index.js'

Vue.use(Vuetify)

const opts = {
	lang: {
		locales: { zhHans, en },
		current: currentLang,
	},
	icons: {
		iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
	},
}

export default new Vuetify(opts)
