export const en = {
  shanghai: "ShangHai",
  mici: " Infos and contact ",
  quzhou: "QuZhou",
  china: "China",
  pdp: "Printed decorative paper",
  zsnmc: "Zhejiang Summer New Material Co.,LTD",
  lnd8: "No.70 donggang 8th Road, Green Industry Cluster Area, Quzhou City, Zhejiang Province",
  cdmc: "Charte Decoration Materials (Shanghai) Co.,LTD",
  kerp: "1189 Kangqiao East Road, Pudong New Area, Shanghai, China",
  contact: "Contact us",
};

export const zhHans = {
  shanghai: "上海",
  mici: "更多信息和联系方式",
  quzhou: "衢州",
  china: "中国",
  pdp: "印刷装饰纸",
  zsnmc: "浙江夏特新材料有限公司",
  lnd8: "浙江省衢州市绿色产业集聚区东港八路70号",
  cdmc: "夏特装饰材料（上海）有限公司",
  kerp: "中国上海市浦东新区康桥东路1189号",
  contact: "联系我们",
};
