var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-page"},[_c('div',{staticClass:"home-large-img-box"},[_c('v-img',{attrs:{"min-height":"600","max-height":"850","src":require('../assets/home/1.jpg')}}),_c('div',{staticClass:"home-large-img-title-box"},[_c('div',{staticClass:"home-large-img-title"},[_c('h1',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.home.h1')))]),_c('p',{staticStyle:{"margin-top":"30px"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.home.p1'))+" ")])])]),_c('span',{staticClass:"home-large-img-icon"})],1),_c('div',{staticClass:"home-swiper-img-box",style:({
			padding: _vm.paddingOne,
		})},[_c('v-img',{staticStyle:{"border-radius":"10px"},attrs:{"height":"300px","src":require('../assets/home/2.jpg')}})],1),_c('div',{staticClass:"home-page-content",style:({
			padding: _vm.paddingTwo,
		})},[_c('h1',{staticClass:"home-page-content-h1"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.home.h2'))+" ")]),_c('p',{staticClass:"home-page-content-p"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.home.p2'))+" ")]),_c('p',{staticClass:"home-page-content-p"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.home.p3'))+" ")]),_c('p',{staticClass:"home-page-content-p"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.home.p4'))+" ")])]),_c('v-container',{staticClass:"home-page-container-box",style:({
			padding: _vm.paddingTwo,
		}),attrs:{"fluid":""}},[_c('h1',{staticClass:"home-page-content-h1"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.home.h3'))+" ")]),_c('v-row',[_c('v-col',{attrs:{"sm":"12","xs":"12","md":"6","lg":"6","xl":"6"}},[_c('h2',{staticClass:"home-page-content-h2"},[_vm._v("INSIGHTS by Schattdecor")]),_c('v-responsive',{attrs:{"aspect-ratio":3 / 2}},[_c('v-img',{staticStyle:{"border-radius":"10px"},attrs:{"src":require('../assets/home/12.jpg')}})],1)],1),_c('v-col',{attrs:{"sm":"12","xs":"12","md":"6","lg":"6","xl":"6"}},[_c('v-responsive',{attrs:{"aspect-ratio":1 / 1}},[_c('v-img',{staticStyle:{"border-radius":"10px"},attrs:{"src":require('../assets/home/13.jpg')}})],1)],1),_c('v-col',{attrs:{"sm":"12","xs":"12","md":"6","lg":"6","xl":"6"}},[_c('p',{class:[
						_vm.deviceType === 'xs' || _vm.deviceType === 'sm'
							? ''
							: 'home-page-content-p-text' ]},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.home.p5'))+" ")])]),_c('v-col',{attrs:{"sm":"12","xs":"12","md":"6","lg":"6","xl":"6"}},[_c('v-responsive',{class:[
						_vm.deviceType === 'xs' || _vm.deviceType === 'sm'
							? ''
							: 'home-page-content-last-img' ],attrs:{"aspect-ratio":1 / 1}},[_c('v-img',{staticStyle:{"border-radius":"10px"},attrs:{"src":require('../assets/home/14.jpg')}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }