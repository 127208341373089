<template>
  <div style="padding: 0px 60px">
    <v-container>
      <v-row no-gutters justify="center" align="center">
        <v-col cols="12" sm="12" xs="12" md="6" lg="6" xl="4">
          <div class="thetitle">
            {{ $vuetify.lang.t("$vuetify.itsystem.bpmtitle") }}
          </div>
          <div style="margin-top: 20px">
            {{ $vuetify.lang.t("$vuetify.itsystem.bpminfo") }}
          </div>
        </v-col>
        <v-col sm="12" xs="12" md="6" lg="4" xl="3" style="" class="bpmImage">
          <v-carousel
            cycle
            style="width: 250px"
            height="600"
            :show-arrows-on-hover="true"
            :hide-delimiter-background="true"
            :light="true"
          >
            <v-carousel-item v-for="(item, index) in bpmImgList" :key="index">
              <div
                style="
                  height: 560px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <v-img @click="toAppStore" :src="item.src"></v-img>
              </div>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        justify="center"
        align="center"
        style="margin-top: 70px; margin-bottom: 60px"
      >
        <v-col sm="12" xs="12" md="6" lg="4" xl="3" class="mpimg">
          <v-carousel
            cycle
            style="width: 250px"
            height="600"
            :show-arrows-on-hover="true"
            :hide-delimiter-background="true"
            :light="true"
          >
            <v-carousel-item
              v-for="(item, index) in DetermineTheLanguage"
              :key="index"
            >
              <div
                style="
                  height: 560px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <v-img @click="toAppStore" :src="item.src"></v-img>
              </div>
            </v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col cols="12" sm="12" xs="12" md="6" lg="6" xl="4">
          <div class="thetitle" style="text-align: right">
            {{ $vuetify.lang.t("$vuetify.itsystem.samplesys") }}
          </div>
          <div style="margin-top: 20px; text-align: right">
            {{ $vuetify.lang.t("$vuetify.itsystem.mpinfo") }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "ItSystem",
  data() {
    return {
      BpmSwiperCurrent: 0,
      mpSwiperCurrent: 0,
      bpmImgList: [
        {
          id: 1,
          src: require("../assets/itSystem/bpmShow1.jpg"),
        },
        {
          id: 2,
          src: require("../assets/itSystem/bpmShow2.jpg"),
        },
        {
          id: 3,
          src: require("../assets/itSystem/BpmQrCode.png"),
        },
      ],
      mpImgList: [
        {
          id: 1,
          src: require("../assets/itSystem/mpShow1.jpg"),
        },
        {
          id: 2,
          src: require("../assets/itSystem/mpShow2.jpg"),
        },
        {
          id: 3,
          src: require("../assets/itSystem/mpShow3.jpg"),
        },
        {
          id: 4,
          src: require("../assets/itSystem/mpQrCode.png"),
        },
      ],
    };
  },
  computed: {
    // 切换语言时图片也更换
    DetermineTheLanguage() {
      if (this.$vuetify.lang.current == "en") {
        return [
          {
            id: 1,
            src: require("../assets/itSystem/mpShow1en.jpg"),
          },
          {
            id: 2,
            src: require("../assets/itSystem/mpShow2en.jpg"),
          },
          {
            id: 3,
            src: require("../assets/itSystem/mpShow3en.jpg"),
          },
          {
            id: 4,
            src: require("../assets/itSystem/mpQrCode.png"),
          },
        ];
      } else {
        return [
          {
            id: 1,
            src: require("../assets/itSystem/mpShow1.jpg"),
          },
          {
            id: 2,
            src: require("../assets/itSystem/mpShow2.jpg"),
          },
          {
            id: 3,
            src: require("../assets/itSystem/mpShow3.jpg"),
          },
          {
            id: 4,
            src: require("../assets/itSystem/mpQrCode.png"),
          },
        ];
      }
    },
  },
  methods: {
    // bpmChange(e) {
    //   this.BpmSwiperCurrent = e;
    // },
    // mpChange(e) {
    //   this.mpSwiperCurrent = e;
    // },
    // bpmClick(index) {
    //   console.log(index);
    // },
    // mpClick() {
    //   // console.log(index);
    //   // this.mpSwiperCurrent = index;
    // },
    toAppStore() {
      window.open(
        "https://apps.apple.com/cn/app/%E5%A4%8F%E7%89%B9bpm/id1543128302"
      );
    },
  },
};
</script>
<style scoped>
.thetitle {
  color: #70808c;
  font-size: 25px;
}
.delimiterBox {
  display: flex;
  position: absolute;
  bottom: 10px;
}
.bpmClass {
  background-color: rgba(0, 0, 0, 0.9);
}
.bigDelimiter {
  background-color: rgba(255, 255, 255, 0.4);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6px;
}
.bigDelimiterActive {
  background-color: rgba(255, 255, 255);
}
.smallDelimiter {
  background-color: rgba(0, 0, 0, 0.2);
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.smallDelimiterActive {
  background-color: rgba(0, 0, 0, 0.9);
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
@media only screen and (max-width: 960px) {
  .bpmImage {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .mpimg {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 960px) {
  .bpmImage {
    display: flex;
    justify-content: right;
  }
  .mpimg {
    display: flex;
    justify-content: left;
    align-items: center;
  }
}
</style>
